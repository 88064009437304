import { createGlobalStyle, keyframes } from "styled-components"
import Theme from "./Theme"

const rain = keyframes`
  from {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  to {
    transform: translate3d(0, 450%, 0) scale(0.9);
    opacity: 0;
  }
`

const GlobalStyles = createGlobalStyle`
  @import url('https://cloud.typography.com/6672892/6721412/css/fonts.css');

  html {
    font-size: 62.5%;
    @media (max-width: ${Theme.maxWidth}) {font-size: 62%;}
    @media (max-width: 980px) {font-size: 58%;}
    @media (max-width: 600px) {font-size: 55%;}
  }

  body {
    min-height: 100vh;
    position: relative;
    font-size: 1.6rem;
    font-family: ${Theme.whitney};
    font-weight: 400;
    padding-bottom: 78px;
    color: ${Theme.slate};

    @media only screen and (max-width: 450px) {
      padding-bottom: 135px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${Theme.whitney};
    font-weight: 600;
    color: ${Theme.navy};
    margin: 0;
    padding: 15px 0;
  }
  h2 {
    font-size: 3.6rem;
    line-height: 4.2rem;      
    font-family: ${Theme.whitney};
    font-weight: bold;
    padding: 0;
  }

  a {
    color: inherit;
    transition: ${Theme.transition};
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  button,
  a.button {
    display: inline-block;
    padding: 8px 25px;
    cursor: pointer;
    background: ${Theme.navy};
    color: ${Theme.white};
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 2.5;
    text-decoration: none;
    border: 0;
    border-radius: 4px;
    outline: 0;
    letter-spacing: 2px;
    transition: all 200ms ease-in-out;

    ins {
      text-decoration: none;
    }

    &:hover,
    &:focus {
      opacity: 0.9;
      box-shadow: 2px 4px 12px rgba(44,51,59,.2);
    }
    &:active {
      transform: translateY(1px);
    }

    &.yellow {
      color: ${Theme.navy};
      background: ${Theme.yellow};
      letter-spacing: 1px;
    }
  }

  figure {
    margin: 0;
  }

  .lang-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    animation: ${rain} 3s ease-in-out;
    opacity: 0;
  }

  .anchor-link {
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
  }  

  /* chrome autofill nonsense */
  @-webkit-keyframes autofill {
    to {
      color: ${Theme.navy};
      background: ${Theme.yellow};
    }
  }
  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }
`
export default GlobalStyles
