import { Box, useScrollTrigger } from "@material-ui/core"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import React, { useEffect } from "react"
import styled from "styled-components"

import ContactButton from "./ContactButton"
import Logo from "../assets/images/aob-logo-inline.svg"
import Theme from "./styles/Theme"

const TopBar = animated(styled(Box)`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 5%;
  background: ${Theme.navy};
  z-index: 10;

  svg {
    width: 182px;
    height: 50px;
  }

  .button {
    font-size: 14px;
    padding: 4px 15px;

    @media only screen and (max-width: 625px) {
      ins { display: none; }
    }
  }
`)

const Header = props => {
  const trigger = useScrollTrigger({ threshold: 200, disableHysteresis: true }),
    [spring, setSpring] = useSpring(() => ({
      transform: `translate3d(0, -100%, 0)`,
    }))

  useEffect(() => {
    setSpring({
      transform: `translate3d(0, ${trigger ? "0" : "-100%"}, 0)`,
    })
  })

  return (
    <TopBar style={spring}>
      <Link to="/">
        <Logo />
      </Link>
      <ContactButton />
    </TopBar>
  )
}

export default Header
