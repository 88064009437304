import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Container from "./Container"
import Logo from "../assets/images/aob-logo-mark.svg"
import Tile from "../assets/tiles/honeycomb-blue.png"
import Theme from "./styles/Theme"
import FacebookSvg from "../assets/icons/facebook.svg"
import TwitterSvg from "../assets/icons/twitter.svg"
import InSvg from "../assets/icons/in.svg"

// styles
const SiteFooter = styled.footer`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: ${Theme.navy};
`,
FooterContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: unset !important;
  padding: 20px 5% !important;

  @media only screen and (max-width: 625px) {
    flex-direction: column;
    align-items: flex-start;
  }

`,
CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
  svg {
    width: 30px;
    height: 34px;
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
`,
SocialLinks = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-left: 15px;
    opacity: .5;
    &:hover, &:focus {
      opacity: 1;
    }
    &:first-of-type {
      margin-left: 4px;
    }
  }

  @media only screen and (max-width: 625px) {
    padding-top: 20px;
  }
`,
Copy = styled.div`
  margin: 0 15px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.07);
  font-size: 13px;
  color: ${Theme.gray};
  @media only screen and (max-width: 450px) {
    margin: 13px 0;
  }
`,
Email = styled.a`
  display: block;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.07);
  font-size: 13px;
  color: ${Theme.gray};

  &:hover, &:focus {
    opacity: 1;
    color: ${Theme.white};
  }
`


const Footer = () => (
  <SiteFooter>
    <FooterContainer>
      <CompanyInfo>
        <Link to="/">
          <Logo />
        </Link>
        <Copy>&copy;2020 Army of Bees, LLC</Copy>
      </CompanyInfo>
      <SocialLinks>
        <Email href="mailto:info@armyofbees.com">info@armyofbees.com</Email>
        {/*
        <a href="https://www.facebook.com/armyovbees/" className="footer-icon" target="_blank">
          <FacebookSvg />
        </a>
        <a href="https://twitter.com/armyofbees" className="footer-icon" target="_blank">
          <TwitterSvg />
        </a>
        */}
        <a href="https://www.linkedin.com/company/army-of-bees/" className="footer-icon" target="_blank">
          <InSvg />
        </a>
      </SocialLinks>
    </FooterContainer>
  </SiteFooter>
)

export default Footer

