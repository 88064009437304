import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import ModernNormalized from "./styles/ModernNormalize"
import GlobalStyles from "./styles/Global"

const Layout = props => (
  <>
    <ModernNormalized />
    <GlobalStyles />
    <Header />
    <main>{props.children}</main>
    <Footer />
  </>
)

export default Layout
