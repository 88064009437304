const breakpoint = "850px",
  Theme = {
    // colors
    navy: "#2C333B",
    slate: "#7C8089",
    yellow: "#FFC200",
    white: "#FFFFFF",
    darkGray: "#68737D",
    gray: "#9BA6B0",
    lightGray: "#EDEDED",
    black: "#2F353C",

    // icon colors / variants
    blue: "#00D2F5",
    lightRed: "#F6763E",
    red: "#C22F13",
    darkYellow: "#E89E0C",
    mediumGray: "#68737D",
    blueGreen: "#3CD7F5",

    // fonts
    whitney: `"Whitney SSm A", "Whitney SSm B", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    sentinel: `"Sentinel A", "Sentinel B", "Domine", georgia, serif`,

    // good defaults
    maxWidth: "1280px",
    maxWidthNumeric: 1280,
    maxInnerWidth: "1240px",
    maxInnerWidthNumeric: 1240,
    transition: "all 150ms ease",

    // easings
    easeOutBack: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
    easeInOutBack: "cubic-bezier(0.68, -0.55, 0.265, 1.55)",
    easeOutQuart: "cubic-bezier(0.165, 0.84, 0.44, 1)",
    easeOutSine: "cubic-bezier(0.39, 0.575, 0.565, 1)",
    easeInOutCubic: "cubic-bezier(0.645, 0.045, 0.355, 1)",

    // "mixins"
    includeCentered: `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,

    smallScreen: `@media (max-width: ${breakpoint})`,
    bigScreen: `@media (min-width: ${breakpoint})`,
    breakpoint,
  }

export default Theme
