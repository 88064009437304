import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

export default ({
  className = "button yellow",
  href = "#contact",
  onClick = ({ target }) => target.blur
}) => (
  <AnchorLink className={className} href={href} onClick={onClick}>
    Contact<ins> Us</ins>
  </AnchorLink>
)
