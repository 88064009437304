import React from "react"
import styled from "styled-components"
import Theme from "./styles/Theme"

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props =>
    props.maxWidth ? props.maxWidth + "px" : Theme.maxWidth};
  margin: 0 auto;
  padding: 0 20px;
`

const Container = props => (
  <StyledContainer className={props.className} maxWidth={props.maxWidth}>
    {props.children}
  </StyledContainer>
)

export default Container
