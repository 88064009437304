/**
 * <head> component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

Head.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default function Head({ title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title || site.siteMetadata.title}
    >
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cloud.typography.com/6672892/6721412/css/fonts.css"
      />
      <meta name="description" content={site.siteMetadata.description} />
      <meta property="og:title" content={title || site.siteMetadata.title} />
      <meta property="og:description" content={site.siteMetadata.description} />
      <meta property="og:image" content={"/aob-logo-og.png"} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="Website" />
      <meta name="twitter:card" content="Summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
        content={site.siteMetadata.description}
      />
    </Helmet>
  )
}
